interface TagProps{
    tag: string;
    color: string;
}

export const Tag = (props: TagProps) =>{
    return(
        <span className={"tag tag-" + props.color}>
            {props.tag}
        </span>
    )
}