import { useEffect, useState } from "react";
import { matchDTO } from "../../../DTOs/MatchHistoryDTOs/MatchDTO";
import { MatchHistoryDTO } from "../../../DTOs/MatchHistoryDTOs/MatchHistoryDTO";
import { Match } from "./Match";
import { statsShowFilter } from "../../../DTOs/StatsShowFilterDTO";
import { LoadingIcon } from "../LoadingIcon";

interface MatchHistoryTabProps {
  name: string;
  version: string;
  matchHistoryLoading: boolean;
  MatchHistoryData: MatchHistoryDTO[];
  HandleBan: (args: string) => void;
  BanList: string[];
  statsShowFilter: statsShowFilter;
}

export const MatchHistoryTab = (props: MatchHistoryTabProps) => {
  const [matchHistoryData, setMatchHistoryData] = useState<matchDTO[]>([]);
  const [maxMatches, setMaxMatches] = useState(5);
  useEffect(() => {
    props.MatchHistoryData.forEach((player: MatchHistoryDTO) => {
      if (player.player === props.name) {
        setMatchHistoryData(player.playerMatches);
        setMaxMatches(30);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.MatchHistoryData]);

  return (
    <>{ matchHistoryData ?
      <>
        {matchHistoryData.length > 0 &&
          matchHistoryData.slice(0, maxMatches).map((match: matchDTO) => {
            return (
              <span key={props.name + match.matchId}>
                <Match
                  match={match}
                  version={props.version}
                  HandleBan={props.HandleBan}
                  BanList={props.BanList}
                  statsShowFilter={props.statsShowFilter}
                />
              </span>
            );
          })}
      </>:<LoadingIcon/>}
    </>
  );
};
