
import { useEffect, useState } from "react";
import { activeRolesValues } from "../../DTOs/ActiveRolesDTO";
import { champQStats } from "../../DTOs/ChampionDTO";
import { playerStats } from "../../DTOs/PlayerStatsDTO";
import { RoleStats } from "../../DTOs/RoleStatsDTO";
import { ChampionStats } from "./ChampionStats";
import { RoleStatsElement } from "./RoleStatsElement";
import { LoadingIcon } from "./LoadingIcon";
import { PlayerDetails } from "./PlayerDetails/PlayerDetails";
import { PlayerDetailStatsDTO } from "../../DTOs/StatsDTOs/PlayerDetailStatsDTO";
import { ChampStatsOverview } from "./ChampStats/ChampStatsOverview";
import { EloImage } from "./EloImage";
import { MatchHistoryTab } from "./MatchHistory/MatchHistoryTab";




export const PlayerStats = (props: playerStats) => {


    const [activeRoles, setActiveRoles] = useState<activeRolesValues>({
        top: true,
        jungle: true,
        mid: true,
        bot: true,
        support: true
    });

    const [activeTab, setActiveTab] = useState<string>("Overview");
    const [TabActiveMatchHistory, setTabActiveMatchHistory] = useState<string>("");
    const [TabActiveChampStats, setTabActiveChampStats] = useState<string>("");
    const [TabActivePlayerStats, setTabActivePlayerStats] = useState<string>("");
    const [TabActiveOverview, setTabActiveOverview] = useState<string>("Tabview-Controls-Element-Active");

    useEffect(() => {
        if (activeTab === "Overview") {
            setTabActiveOverview("Tabview-Controls-Element-Active");
            setTabActiveChampStats("");
            setTabActivePlayerStats("");
            setTabActiveMatchHistory("");
        }
        else if (activeTab === "ChampStats") {
            setTabActiveOverview("");
            setTabActiveChampStats("Tabview-Controls-Element-Active");
            setTabActivePlayerStats("");
            setTabActiveMatchHistory("");
        } else if (activeTab === "PlayerStats") {
            setTabActiveOverview("");
            setTabActiveChampStats("");
            setTabActivePlayerStats("Tabview-Controls-Element-Active");
            setTabActiveMatchHistory("");
        }
        else if (activeTab === "MatchHistory") {
            setTabActiveOverview("");
            setTabActiveChampStats("");
            setTabActivePlayerStats("");
            setTabActiveMatchHistory("Tabview-Controls-Element-Active");
        }
    }, [activeTab])

    const Details = () => {
        let dataStat: PlayerDetailStatsDTO | undefined;
        if (props.PlayerDetails !== undefined) {
            props.PlayerDetails.stats.forEach(stat => {
                if (props.name === stat.name) {
                    dataStat = stat;
                }
            })
        }

        return (<>
            {dataStat &&
                <PlayerDetails details={dataStat} HandleBan={props.HandleBan} BanList={props.BanList} version={props.version} />
            }
        </>);

    }

    return (
        <div className="player">
            <div className="player-header">
                <div className="player-stats">
                    <span className="player-stats-basic">
                        <span className="player-stats-basic-img">
                            <EloImage rank={null} quue={"Solo"} rankStr={props.elo} />
                        </span>
                        <span className="player-stats-basic-base">
                            <span className="player-stats-basic-name" >{props.name}</span>
                            <span className="player-stats-basic-elo">{props.elo}</span>
                        </span>
                        <span className="player-stats-basic-stats">
                            <table>
                                <tbody>
                                <tr>
                                    <td><b>games:</b></td>
                                    <td >
                                        <span>
                                            <b>{props.games}</b>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>winrate:</b></td>
                                    <td>
                                        <b>{props.winrate as number >= 50 ?
                                            <span className="champion-stats-winrate-positiv">{props.winrate || (props.winrate === 0 ? "0%" : "-")}{(props.winrate && "%") || ""}</span> :
                                            <span className="champion-stats-winrate-negativ">{props.winrate || (props.winrate === 0 ? "0%" : "-")}{(props.winrate && "%") || ""}</span>}
                                        </b>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </span>
                    </span>

                    <span className="player-stats-roles">
                        {props.roleStats.map((role: RoleStats) => {
                            return <RoleStatsElement key={role.role} role={role.role} games={role.games} allGames={props.games} winrate={role.winrate} kda={role.kda} activeRoles={activeRoles} setter={setActiveRoles} />
                        })}
                    </span>
                </div>
                <div className="Tabview-Controls">
                    <span className={"Tabview-Controls-Element " + TabActiveOverview} onClick={() => setActiveTab("Overview")}>
                        Overview
                    </span>
                    <span className={"Tabview-Controls-Element " + TabActivePlayerStats} onClick={() => setActiveTab("PlayerStats")}>
                        Player Stats
                    </span>
                    <span className={"Tabview-Controls-Element " + TabActiveChampStats} onClick={() => setActiveTab("ChampStats")}>
                        Champ Stats
                    </span>
                    <span className={"Tabview-Controls-Element " + TabActiveMatchHistory} onClick={() => setActiveTab("MatchHistory")}>
                        Match History
                    </span>
                </div>
            </div>
            <span>
                {activeTab === "Overview" &&
                    <div>
                        <ChampStatsOverview stats={props.champStats} HandleBan={props.HandleBan} BanList={props.BanList} version={props.version} activeRoles={activeRoles} filter={props.filter} />
                    </div>}
                {activeTab === "ChampStats" &&
                    <span>
                        <div className="player-champs">
                            {props.roles.map((role: any) => {

                                if ((role.role === "TOP" && !activeRoles.top) ||
                                    (role.role === "JUNGLE" && !activeRoles.jungle) ||
                                    (role.role === "MIDDLE" && !activeRoles.mid) ||
                                    (role.role === "UTILITY" && !activeRoles.support) ||
                                    (role.role === "BOTTOM" && !activeRoles.bot)) {
                                    return <></>
                                }

                                return role.champs.map((champ: any) => {
                                    let normalQ: champQStats = {};
                                    let soloQ: champQStats = {};
                                    let flexQ: champQStats = {};
                                    let clash: champQStats = {};
                                    let tournament: champQStats = {};

                                    champ.queues.map((q: any) => {
                                        if (q.queue === "normal") {
                                            normalQ = q
                                        }
                                        else if (q.queue === "soloQ") {
                                            soloQ = q
                                        }
                                        else if (q.queue === "flexQ") {
                                            flexQ = q
                                        }
                                        else if (q.queue === "clash") {
                                            clash = q
                                        }
                                        else if (q.queue === "tournament") {
                                            tournament = q
                                        }

                                        return true
                                    })

                                    let showChamp = true;
                                    const allNull = (props.filter.normalQ !== true && props.filter.soloQ !== true && props.filter.flexQ !== true && props.filter.clash !== true && props.filter.tournament !== true);

                                    const normalFilter = (normalQ.games as number >= props.filter.games &&
                                        normalQ.winrate as number >= props.filter.winrate &&
                                        normalQ.kda as number >= props.filter.kda &&
                                        normalQ.goldPerMinute as number >= props.filter.gold &&
                                        normalQ.csPerMinute as number >= props.filter.cs);

                                    const soloQFilter = (soloQ.games as number >= props.filter.games &&
                                        soloQ.winrate as number >= props.filter.winrate &&
                                        soloQ.kda as number >= props.filter.kda &&
                                        soloQ.goldPerMinute as number >= props.filter.gold &&
                                        soloQ.csPerMinute as number >= props.filter.cs);

                                    const flexQFilter = (flexQ.games as number >= props.filter.games &&
                                        flexQ.winrate as number >= props.filter.winrate &&
                                        flexQ.kda as number >= props.filter.kda &&
                                        flexQ.goldPerMinute as number >= props.filter.gold &&
                                        flexQ.csPerMinute as number >= props.filter.cs);

                                    const clashFilter = (clash.games as number >= props.filter.games &&
                                        clash.winrate as number >= props.filter.winrate &&
                                        clash.kda as number >= props.filter.kda &&
                                        clash.goldPerMinute as number >= props.filter.gold &&
                                        clash.csPerMinute as number >= props.filter.cs);

                                    const TournamentFilter = (tournament.games as number >= props.filter.games &&
                                        tournament.winrate as number >= props.filter.winrate &&
                                        tournament.kda as number >= props.filter.kda &&
                                        tournament.goldPerMinute as number >= props.filter.gold &&
                                        tournament.csPerMinute as number >= props.filter.cs);

                                    if (allNull) {
                                        if (!(normalFilter ||
                                            soloQFilter ||
                                            flexQFilter ||
                                            clashFilter ||
                                            TournamentFilter)) {
                                            showChamp = false;
                                        }
                                    } else if (!(
                                        (props.filter.normalQ !== true ||
                                            normalFilter) &&

                                        (props.filter.soloQ !== true ||
                                            soloQFilter) &&

                                        (props.filter.flexQ !== true ||
                                            flexQFilter) &&

                                        (props.filter.clash !== true ||
                                            clashFilter) &&

                                        (props.filter.tournament !== true ||
                                            TournamentFilter)
                                    )) {
                                        showChamp = false;
                                    }

                                    if (
                                        (props.filter.normalQ === false || !normalQ.games) &&
                                        (props.filter.soloQ === false || !soloQ.games) &&
                                        (props.filter.flexQ === false || !flexQ.games) &&
                                        (props.filter.clash === false || !clash.games) &&
                                        (props.filter.tournament === false || !tournament.games)
                                    ) {
                                        showChamp = false;
                                    }

                                    if (showChamp) {
                                        return (<ChampionStats key={role.role + champ.name}
                                            role={role.role}
                                            champ={champ.name}
                                            normalQ={normalQ}
                                            soloQ={soloQ}
                                            flexQ={flexQ}
                                            clash={clash}
                                            tournament={tournament}
                                            version={props.version}
                                            filterStats={props.filter}
                                            showStats={props.showStats}
                                            HandleBan={props.HandleBan}
                                            BanList={props.BanList} />)
                                    }


                                    return (<></>)
                                })
                            })}
                        </div>
                    </span>
                }
                {activeTab === "PlayerStats" &&
                    <span>
                        {props.statsLoading &&
                            <div className="player-details-loading">
                                <LoadingIcon />
                            </div>
                        }
                        {!props.statsLoading && props.PlayerDetails !== undefined &&
                            <div className="player-details">
                                <Details />
                            </div>
                        }
                    </span>
                }
                {activeTab === "MatchHistory" &&
                    <span>
                        <MatchHistoryTab
                            name={props.name}
                            version={props.version}
                            matchHistoryLoading={props.matchHistoryLoading}
                            MatchHistoryData={props.MatchHistoryData}
                            HandleBan={props.HandleBan}
                            BanList={props.BanList} 
                            statsShowFilter={props.showStats}                        />
                    </span>
                }
            </span>
        </div>
    );
}