import { useEffect, useState } from "react";
import { matchDTO } from "../../../DTOs/MatchHistoryDTOs/MatchDTO";
import { Tag } from "./Tag";
import { matchPlayerDTO } from "../../../DTOs/MatchHistoryDTOs/MatchPlayerDTO";
import { ChampImg } from "../ChampImg";
import { LoadingIcon } from "../LoadingIcon";
import { statsShowFilter } from "../../../DTOs/StatsShowFilterDTO";
import config from '../../../assets/config/config.json';
interface MatchProps {
  match: matchDTO;
  version: string;
  HandleBan: (args: string) => void;
  BanList: string[];
  statsShowFilter: statsShowFilter;
}

export const Match = (props: MatchProps) => {
  const getDate = (timestamp: number) => {
    let time = new Date(timestamp);
    return (
      time.getDay() +
      "." +
      time.getMonth() +
      "." +
      time.getFullYear() +
      " - " +
      time.getHours() +
      ":" +
      time.getMinutes()
    );
  };

  const [winclass, setWinclass] = useState("match-win");
  const [playerData, setPlayerData] = useState<matchPlayerDTO>();
  useEffect(() => {
    if (props.match.win) {
      setWinclass("match-win");
    } else {
      setWinclass("match-lost");
    }
    if (props.match.playerSide === "blue") {
      props.match.blueSide.forEach((player) => {
        if (player.name === props.match.name) {
          setPlayerData(player);
        }
      });
    } else if (props.match.playerSide === "red") {
      props.match.redSide.forEach((player) => {
        if (player.name === props.match.name) {
          setPlayerData(player);
        }
      });
    }
  }, []);

  return (
    <div className={"match " + winclass}>
      {playerData?.name ? (
        <>
          <div className="match-headline">
            <span className="match-headline-time">
              {getDate(props.match.gameCreation)}
            </span>
            <span className="match-headline-tags">
              <Tag tag={props.match.queue} color="gray" />
              {props.match.playerSide === "red" && (
                <Tag tag="Redside" color="red" />
              )}
              {props.match.playerSide === "blue" && (
                <Tag tag="Blueside" color="blue" />
              )}
              {playerData?.firstBloodKill && (
                <Tag tag="First Blood" color="red" />
              )}

              {playerData?.baronKills !== undefined &&
                playerData.baronKills === 1 && (
                  <Tag
                    tag={playerData.baronKills + " Baron Kill"}
                    color="purple"
                  />
                )}
              {playerData?.baronKills !== undefined &&
                playerData.baronKills > 1 && (
                  <Tag
                    tag={playerData.baronKills + " Baron Kills"}
                    color="purple"
                  />
                )}

              {playerData?.dragonKills !== undefined &&
                playerData.dragonKills === 1 && (
                  <Tag
                    tag={playerData.dragonKills + " Dragon Kill"}
                    color="orange"
                  />
                )}
              {playerData?.dragonKills !== undefined &&
                playerData.dragonKills > 1 && (
                  <Tag
                    tag={playerData.dragonKills + " Dragon Kills"}
                    color="orange"
                  />
                )}

              {playerData?.quadraKills !== undefined &&
                playerData.quadraKills === 1 && (
                  <Tag
                    tag={playerData.quadraKills + " Quadra Kill"}
                    color="yellow"
                  />
                )}
              {playerData?.quadraKills !== undefined &&
                playerData.quadraKills > 1 && (
                  <Tag
                    tag={playerData.quadraKills + " Quadra Kills"}
                    color="yellow"
                  />
                )}

              {playerData?.pentaKills !== undefined &&
                playerData.pentaKills === 1 && (
                  <Tag
                    tag={playerData.pentaKills + " Penta Kill"}
                    color="yellow"
                  />
                )}
              {playerData?.pentaKills !== undefined &&
                playerData.pentaKills > 1 && (
                  <Tag
                    tag={playerData.pentaKills + " Penta Kills"}
                    color="yellow"
                  />
                )}
            </span>
          </div>
          <div className="match-content">
            <div className="match-content-player">
              <div>
                <span className="match-content-player-img">
                  <ChampImg
                    name={playerData.champ}
                    version={props.version}
                    banList={props.BanList}
                    HandleBan={props.HandleBan}
                  />
                </span>
                <span className="match-content-player-sums">
                <img alt={""} src={config.riot_champ_img_base_url + props.version + "/img/champion/.png"} />
                </span>
              </div>
              <div className="match-content-player-stats">
                {props.statsShowFilter.kda && (
                  <span className="match-content-player-stats-stat">
                    {" "}
                    <b>K/D/A:</b> <span>{playerData.kda}</span>{" "}
                  </span>
                )}

                {props.statsShowFilter.kills && (
                  <span className="match-content-player-stats-stat">
                    {" "}
                    <b>Kills:</b> <span>{playerData.kills}</span>
                  </span>
                )}

                {props.statsShowFilter.deaths && (
                  <span className="match-content-player-stats-stat">
                    {" "}
                    <b>Deaths:</b> <span>{playerData.deaths} </span>
                  </span>
                )}

                {props.statsShowFilter.assists && (
                  <span className="match-content-player-stats-stat">
                    {" "}
                    <b>Assists:</b> <span>{playerData.assists}</span>
                  </span>
                )}

                {props.statsShowFilter.gold && (
                  <span className="match-content-player-stats-stat">
                    {" "}
                    <b>Gold/min:</b> <span>{playerData.goldPerMinute}</span>{" "}
                  </span>
                )}

                {props.statsShowFilter.cs && (
                  <span className="match-content-player-stats-stat">
                    {" "}
                    <b>CS/min:</b> <span>{playerData.csPerMinute}</span>{" "}
                  </span>
                )}
                {props.statsShowFilter.visionScore && (
                  <span className="match-content-player-stats-stat">
                    {" "}
                    <b>vision Score:</b> <span>{playerData.visionScore}</span>{" "}
                  </span>
                )}
              </div>
            </div>
            <div className="match-content-team"></div>
          </div>
        </>
      ) : (
        <LoadingIcon />
      )}
    </div>
  );
};
